import { builderStepsData, builderSystem } from '@/constants/sytemBuilderSteps'
import { useRouter } from 'next/router'
import { createContext, useState } from 'react'

const SystemBuilderContext = createContext()

export const SystemBuilderProvider = ({ children }) => {
  const [selectedProductList, setSelectedProductList] = useState([])
  const [builderSteps, setUpdateBuilderSteps] = useState(builderStepsData)
  const [system, setSystem] = useState(builderSystem)
  const [allTypeAttributeId, setAllTypeAttributeId] = useState([])
  const [stepLoading, setStepLoading] = useState(false)
  const [allStepResult, setAllStepResult] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)

  const router = useRouter()

  const addProductToBag = (
    product_id,
    var_id,
    image_url,
    size,
    price,
    salePrice,
    modifiers,
    customFields,
    sku,
    product
  ) => {
    const tempData = selectedProductList.filter((product) => product.product_id !== product_id)
    setSelectedProductList([
      ...tempData,
      { product_id, var_id, image_url, size, price, salePrice, modifiers, customFields, sku, fullproduct: product },
    ])
  }

  const removeProductFromBag = (product_id, var_id) => {
    const tempData = selectedProductList.filter(
      (product) => product.product_id !== product_id || product.var_id !== var_id
    )
    setSelectedProductList([...tempData])
  }

  const emptyBag = () => {
    setSelectedProductList([])
  }

  const updateBuilderNextSteps = (systemData1) => {
    const startIndex = router?.asPath?.split('/')?.indexOf('system-builder')
    let attributeDataArray = []

    if (startIndex !== -1) {
      attributeDataArray = router?.asPath?.split('/')?.slice(startIndex + 1)
    }

    const stepsArr = builderStepsData?.map((step) => step.name)
    const currentStepIndex = stepsArr?.findIndex((step) => step == systemData1?.attribute_type)

    attributeDataArray = attributeDataArray?.slice(0, currentStepIndex)
    attributeDataArray.push(systemData1?.short_name)

    const nextUrl = '/system-builder/' + attributeDataArray?.join('/')

    router.push(nextUrl)
  }

  const updateBuilderBackSteps = (currentStep) => {
    let newState = [...builderSteps]

    newState[currentStep].complete = false
    newState[currentStep].active = false
    newState[currentStep - 1].active = true
    newState[currentStep - 1].complete = false

    setUpdateBuilderSteps(newState)
  }

  const valueToShare = {
    selectedProductList,
    addProductToBag,
    removeProductFromBag,
    emptyBag,
    builderSteps,
    setUpdateBuilderSteps,
    updateBuilderNextSteps,
    updateBuilderBackSteps,
    system,
    setSystem,
    setAllTypeAttributeId,
    allTypeAttributeId,
    setStepLoading,
    stepLoading,
    setAllStepResult,
    allStepResult,
    dataLoaded,
    setDataLoaded,
  }

  return (
    <SystemBuilderContext.Provider value={valueToShare}>{children}</SystemBuilderContext.Provider>
  )
}

export default SystemBuilderContext
